import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="outscale"
    name="Outscale"
    shortDescription="Store your server file and database backups to Outscale. Secure backups on Outscale like a breeze!"
    website={"https://outscale.com/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="outscale-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Outscale')}
    metaDescription={meta.description.replace('##provider##', 'Outscale')}
  >
    <IntegrationStorageContent
      keyRef="outscale"
      service="Outscale"
      provider="Outscale"
      learnTags={['outscale']}
      contentBlocks={[{title: 'Minimal Outscale access is needed', text: 'When you connect SimpleBackups to your Outscale account, SimpleBackups is only given access to its own application folder. Meaning that access is only limited to that folder on Outscale and not your whole storage.\n'}]}
    />
  </IntegrationTemplate>
)

export default ContentPage
